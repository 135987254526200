import { useState, useMemo } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormik, FormikErrors } from "formik";

import { Category, Upscale, Pricing, Currency } from "../types";
import { animatedgradient } from "../constants";

interface FormValues {
  email: string;
  category: string;
  upscale: string;
}

const currenySymbol = {
  [Currency.EUR]: "€",
  [Currency.INR]: "₹",
  [Currency.USD]: "$",
};

const getFormattedPrice = (price: number, userCurrency: Currency) => {
  return `${currenySymbol[userCurrency]}${price}`;
};

function BuyForm({ pricing }: { pricing: Pricing }) {
  const [upscale, selectUpscale] = useState<Upscale>();
  const [userCurrency, setUserCurrency] = useState(Currency.USD);

  const formik = useFormik({
    initialValues: {
      email: "",
      category: Category.Woman,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const data = {
        ...values,
        upscale,
        currency: userCurrency,
      };
      await fetch(`https://serve.aipix.me/checkout`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => window.location.replace(data.url))
        .catch((error) => console.log("err", error));
    },
  });

  const discountedPrices = useMemo(
    () => ({
      [Upscale.L]: pricing![userCurrency].l / 100,
      [Upscale.XL]: pricing![userCurrency].xl / 100,
      [Upscale.M]: pricing![userCurrency].m / 100,
    }),
    [pricing, userCurrency]
  );

  const originalPrices = useMemo(
    () => ({
      [Upscale.L]: Math.round(discountedPrices[Upscale.L] * 1.2),
      [Upscale.XL]: Math.round(discountedPrices[Upscale.XL] * 1.2),
      [Upscale.M]: Math.round(discountedPrices[Upscale.M] * 1.2),
      [Upscale.L]: Math.round(discountedPrices[Upscale.L] * 1.2),
    }),
    [discountedPrices]
  );

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack
        justify="center"
        align="center"
        spacing={[12, "150px"]}
        direction={["column", null, null, "row"]}
      >
        <VStack spacing={10} align="flex-start">
          <FormControl>
            <FormLabel htmlFor="category" color="white">
              Generate pictures for:
            </FormLabel>
            <Select
              id="category"
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="flushed"
              color="white"
            >
              <option value={Category.Woman}>Woman</option>
              <option value={Category.Man}>Man</option>
              <option value={Category.Cat}>Cat</option>
              <option value={Category.Dog}>Dog</option>
            </Select>
          </FormControl>
          <FormControl
            isInvalid={formik.touched.email && !!formik.errors.email}
          >
            <FormLabel htmlFor="email" color="white">
              Your email address
            </FormLabel>
            <Input
              placeholder="joe@gmail.com"
              variant="flushed"
              id="email"
              type="email"
              name="email"
              color="white"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            <FormHelperText color="gray.300">
              This is where the generated pictures will be sent.
            </FormHelperText>
          </FormControl>
        </VStack>

        <VStack spacing={8} align="stretch">
          <Stack direction="row" align="center" justify="flex-end">
            <Text>View prices in </Text>
            <Select
              width="fit-content"
              flexGrow={0}
              value={userCurrency}
              onChange={(e) => setUserCurrency(e.target.value as Currency)}
            >
              <option value={Currency.EUR}>EUR</option>
              <option value={Currency.INR}>INR</option>
              <option value={Currency.USD}>USD</option>
            </Select>
          </Stack>
          <VStack align="stretch">
            <Button
              size="lg"
              onClick={() => {
                selectUpscale(Upscale.L);
                formik.submitForm();
              }}
              width="full"
              isLoading={formik.isSubmitting && upscale === Upscale.L}
              borderColor="whiteAlpha.700"
              variant="outline"
              bgGradient="linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)"
              backgroundSize="300% 300%"
              animation={`${animatedgradient} 6s ease infinite alternate`}
              _hover={{
                bgGradient:
                  "linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)",
              }}
            // _hover={{
            //   bgGradient:
            //     "linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)",
            //   backgroundSize: "300% 300%",
            //   animation: `${animatedgradient} 6s ease infinite alternate`,
            //   borderColor: "transparent",
            // }}
            >
              <Stack direction="row" spacing={1}>
                <Text>Order 120+ HD images -</Text>
                <Text decoration="line-through" textDecorationThickness="2px">
                  {getFormattedPrice(originalPrices[Upscale.L], userCurrency)}
                </Text>
                <Text>
                  {getFormattedPrice(discountedPrices[Upscale.L], userCurrency)}
                </Text>
              </Stack>
            </Button>
            <Text textAlign="center" fontSize={["sm", "md"]}>
              You get 15+ styles with this. <br />
              HD images are 2048x2048, in Full HD, <br />
              great for social media and wallpapers. <br />
              (Upscale to 4K/8K for printing is available for free too!)
            </Text>
          </VStack>
          {/* <VStack align="stretch">
            <Button
              size="lg"
              onClick={() => {
                selectUpscale(Upscale.XL);
                formik.submitForm();
              }}
              width="full"
              isLoading={formik.isSubmitting && upscale === Upscale.XL}
              bgGradient="linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)"
              backgroundSize="300% 300%"
              animation={`${animatedgradient} 6s ease infinite alternate`}
              _hover={{
                bgGradient:
                  "linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)",
              }}
            >
              <Stack direction="row" spacing={1}>
                <Text>Order 15+ styles in 4k -</Text>
                <Text decoration="line-through" textDecorationThickness="2px">
                  {getFormattedPrice(originalPrices[Upscale.XL], userCurrency)}
                </Text>
                <Text>
                  {getFormattedPrice(
                    discountedPrices[Upscale.XL],
                    userCurrency
                  )}
                </Text>
              </Stack>
            </Button>
            <Text textAlign="center" fontSize={["sm", "md"]}>
              4K images are 4096x4096 <br />
              great for printing or any media
            </Text>
          </VStack> */}
          {/* <VStack align="stretch">
            <Button
              size="lg"
              onClick={() => {
                selectUpscale(Upscale.M);
                formik.submitForm();
              }}
              width="full"
              isLoading={formik.isSubmitting && upscale === Upscale.M}
              borderColor="whiteAlpha.700"
              variant="outline"
              _hover={{
                bgGradient:
                  "linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)",
                backgroundSize: "300% 300%",
                animation: `${animatedgradient} 6s ease infinite alternate`,
                borderColor: "transparent",
              }}
            >
              <Stack direction="row" spacing={1}>
                <Text>Order 15+ styles -</Text>
                <Text decoration="line-through" textDecorationThickness="2px">
                  {getFormattedPrice(originalPrices[Upscale.M], userCurrency)}
                </Text>
                <Text>
                  {getFormattedPrice(discountedPrices[Upscale.M], userCurrency)}
                </Text>
              </Stack>
            </Button>
            <Text textAlign="center" fontSize={["sm", "md"]}>
              Regular images are 1024x1024, medium-resolution, <br />
              great for profile pictures and social media
            </Text>
          </VStack> */}
        </VStack>
      </Stack>
    </form>
  );
}

export { BuyForm };
