import { useEffect, useRef, useMemo } from "react";
import { Box, Grid, Stack, Heading, Image, Flex } from "@chakra-ui/react";
import placeholder from "../images/input/input1.jpg";

function importAll(r: __WebpackModuleApi.RequireContext) {
  return r.keys().map((item, index) => {
    return r(item);
  });
}

const images = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
);

function Cover() {
  const sortedImages = useMemo(() => [...images].sort(), []);
  const imageRefs = useRef<Array<HTMLImageElement | null>>([]);

  useEffect(() => {
    const lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target as HTMLImageElement;
          lazyImage.src = lazyImage.dataset.src as string;
          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    imageRefs.current.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage!);
    });
  }, []);
  return (
    <Box position="relative">
      <Box height="100vh" overflow="hidden" position="relative">
        <Grid templateColumns="repeat(15, max-content)" gap={5}>
          {sortedImages.map((image: any, index) => (
            <Image
              className="lazy-loaded-image lazy"
              src={placeholder}
              data-src={image}
              height="180px"
              width="180px"
              objectFit="cover"
              rounded="md"
              ref={(el) => (imageRefs.current[index] = el)}
            />
          ))}
        </Grid>
        <Box
          bgColor="rgba(0,0,0,0.7)"
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />
        <Flex
          position="absolute"
          justify="center"
          align="center"
          top="0"
          left="0"
          bottom="0"
          right="0"
        >
          <Stack spacing={4} alignItems="center">
            <Heading
              as="h1"
              fontSize="7xl"
              background="-webkit-linear-gradient(45deg, #f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)"
              backgroundClip="text"
              textColor="transparent"
              backgroundSize={["300px", "100px"]}
              px={4}
            >
              AI Pix
            </Heading>
            <Heading as="h2" fontSize="2xl" px={4} textAlign="center">
              Generate HD portraits for a Person 👨🏿 👩🏻 or for a Pet 🐶 🐱
            </Heading>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

export default Cover;
