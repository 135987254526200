import { useState, useEffect, createContext } from "react";
import {
  Box,
  Container,
  Flex,
  Stack,
  Text,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { Pricing } from "../types";
import Footer from "../Footer";
import Header from "../Header";
import { getPricing } from "../apis";
import Process from "../Process";
import { BuyForm, BuyFormContainer } from "../BuyForm";
import Examples from "../Examples";
import TermsOfUse from "../TermsOfUse";
import { ThemeContext, THEME } from "./themeContext";
import Cover from "../LandingCover";
import { PRICING as pricing } from "../data";

export const App = () => {
  return (
    <ThemeContext.Provider value={THEME}>
      <Box w="full" overflowX="hidden">
        <Container
          maxW="full"
          minHeight="100vh"
          position="relative"
          pb={20}
          px={0}
          pt={0}
        >
          <Cover />
          <Process />
          <Stack spacing={8} mt={16}>
            <Text textAlign="center" fontSize={["md", "lg"]} px={4}>
              See some generated samples below 👇
            </Text>
            <BuyFormContainer>
              <BuyForm pricing={pricing as Pricing} />
            </BuyFormContainer>
          </Stack>
          <Examples />
          <TermsOfUse />

          <Footer />
        </Container>
      </Box>
    </ThemeContext.Provider>
  );
};
