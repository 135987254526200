import {
  useLoaderData,
  LoaderFunctionArgs,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  ListIcon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Category, Upscale, OrderStatus } from "./types";
import { UPLOAD_DOS, UPLOAD_DONTS } from "./data";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { animatedgradient, MAX_UPLOADS } from "./constants";
import Tracker from "./Tracker";
import { getEta } from "./apis";
import ImageUploader from "./ImageUploader";
import Footer from "./Footer";
import Header from "./Header";

type Order = {
  email: string;
  category: Category;
  upscale: Upscale;
  status: OrderStatus;
  _id: string;
};

type ETA = {
  eta: string;
  eta_text: string;
};

type LoaderData = {
  order: Order;
  eta: ETA;
};

interface QueuedUploadImage {
  id: string;
  file: File;
  localUrl: string;
}

interface UploadImage extends QueuedUploadImage {
  isUploading: boolean;
  serverUrl?: string;
  isServerError?: Error;
}

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const response = await fetch(
    `https://serve.aipix.me/order/${params.orderId}`,
    {}
  );
  const order = (await response.json()) as Order;
  let eta;
  if (order.status === OrderStatus.SUBMITTED) {
    eta = await getEta(order._id);
  }
  return { order, eta };
};

function Dashboard() {
  const location = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { order, eta } = useLoaderData() as LoaderData;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [isUploadDone, setUploadDone] = useState(false);
  const [uploadedImages, setUploadImages] = useState<UploadImage[]>([]);

  const isUploading = uploadedImages.some((image) => image.isUploading);

  const numberOfSuccessfulImages = uploadedImages.filter(
    (image) => image.serverUrl
  ).length;

  const navigate = useNavigate();

  const uploadImages = async () => {
    setSubmitting(true);
    const data = uploadedImages
      .filter((image) => image.serverUrl)
      .map((image) => image.serverUrl);

    await fetch(`https://serve.aipix.me/submit?order_id=${order._id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
      })
      .catch((error) => console.log("err", error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Container maxW="4xl" minHeight="100vh" position="relative" pt={5} pb={12}>
      <Header />
      {order.status === OrderStatus.CREATED ? (
        <>
          <Stack spacing={16} my={16}>
            <Stack spacing={4}>
              <Heading as="h2" fontSize="2xl">
                Upload guidelines
              </Heading>
              <Stack spacing={4}>
                <List spacing={1}>
                  {UPLOAD_DOS.map((item, index) => (
                    <ListItem key={index}>
                      <ListIcon as={CheckIcon} color="green.500" />
                      {item}
                    </ListItem>
                  ))}
                </List>
                <List spacing={1}>
                  {UPLOAD_DONTS.map((item, index) => (
                    <ListItem key={index}>
                      <ListIcon as={CloseIcon} color="red.500" />
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Stack>
            <Stack spacing={[16, 20]} my={[16, 20]}>
              <ImageUploader
                order={order}
                setUploadDone={setUploadDone}
                setUploadImages={setUploadImages}
              />

              <Flex justify="center" direction="column" align="center">
                <Button
                  onClick={onOpen}
                  isDisabled={
                    !isUploadDone ||
                    isUploading ||
                    numberOfSuccessfulImages > MAX_UPLOADS
                  }
                  bgGradient="linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)"
                  backgroundSize="300% 300%"
                  animation={`${animatedgradient} 6s ease infinite alternate`}
                  color="white"
                  _hover={{
                    bgGradient:
                      "linear(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)",
                  }}
                  size="lg"
                >
                  Submit
                </Button> <br />
                <Text align="center">⚠️ Make sure the face is zoomed-in and in-frame in all the previews</Text>
                {numberOfSuccessfulImages > MAX_UPLOADS && (
                  <Stack spacing={1} mt={3} fontSize="sm">
                    <Text align="center">
                      Total number of images selected:{" "}
                      {numberOfSuccessfulImages}
                    </Text>
                    <Text align="center">
                      Maximum numbers of images allowed: {MAX_UPLOADS}
                    </Text>
                  </Stack>
                )}
              </Flex>
            </Stack>
          </Stack>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Finalize
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You won't be able to modify your image selection
                  after this and we will begin processing your images.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="purple"
                    onClick={async () => {
                      await uploadImages();
                      onClose();
                      navigate(location.pathname);
                    }}
                    ml={3}
                    isLoading={isSubmitting}
                  >
                    Yes, Submit!
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      ) : (
        <Tracker
          orderStatus={order.status}
          orderEmail={order.email}
          etaText={eta?.eta_text}
        />
      )}

      <Footer />
    </Container>
  );
}

export default Dashboard;
