import {
  Container,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import Header from "../Header";
import Footer from "../Footer";
import TryPrompts from "./TryPrompts";
import DummyOrder from "./DummyOrder";

function AdminDashboard() {
  return (
    <Container maxW="4xl" minHeight="100vh" position="relative" pt={8} pb={12}>
      <Stack spacing={16}>
        <Header />
        <Tabs isLazy lazyBehavior="unmount">
          <TabList>
            <Tab>Place order</Tab>
            <Tab>Try prompts</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <DummyOrder />
            </TabPanel>
            <TabPanel>
              <TryPrompts />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Footer />
    </Container>
  );
}

export default AdminDashboard;
