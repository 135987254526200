import { useRouteError } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Flex h="100vh" justify="center" align="center" direction="column">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{(error as any).statusText || (error as any).message}</i>
      </p>
    </Flex>
  );
}

export default ErrorPage;
