import {
  Box,
  Circle,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ThemeContext } from "./App/themeContext";

function Process() {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW={theme.maxW} mt={16}>
      <SimpleGrid columns={[1, 2, 3]} spacing={12}>
        <Box>
          <Stack spacing="10" align="center">
            <Circle size="40px" bg="black" color="white">
              1
            </Circle>
            <Stack spacing="5" align="center">
              <Heading fontSize="lg" textAlign="center" fontWeight="semibold">
                Upload a few photos from your gallery
              </Heading>
              <Text textAlign="center" fontSize="lg" color="gray.600">
                👨🏻 📷 📤
              </Text>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack spacing="10" align="center">
            <Circle size="40px" bg="black" color="white">
              2
            </Circle>
            <Stack spacing="5" align="center">
              <Heading fontSize="lg" textAlign="center" fontWeight="semibold">
                Our AI will start training for you
              </Heading>
              <Text textAlign="center" fontSize="lg" color="gray.600">
                🤖 🧠 🦾
              </Text>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack spacing="10" align="center">
            <Circle size="40px" bg="black" color="white">
              3
            </Circle>
            <Stack spacing="5" align="center">
              <Heading fontSize="lg" textAlign="center" fontWeight="semibold">
                Get over 120 pictures in your inbox
              </Heading>
              <Text textAlign="center" fontSize="lg" color="gray.600">
                👨🏻 ➡️ 👨🏻‍🎤
              </Text>
            </Stack>
          </Stack>
        </Box>
      </SimpleGrid>
    </Container>
  );
}

export default Process;
