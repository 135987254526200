import { createContext } from "react";

type Theme = {
  maxW: string;
};

export const THEME: Theme = {
  maxW: "5xl",
};

export const ThemeContext = createContext<Theme>(THEME);
