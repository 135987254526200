import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Stack,
  VStack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { Category, Upscale } from "../types";
import { useFormik, FormikErrors } from "formik";

interface FormValues {
  email: string;
  category: Category;
}

function DummyOrder() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const secretInputRef = useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: "",
      category: Category.Man,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const secret = localStorage.getItem("promptSecret");

      if (!secret) {
        onOpen();
        return;
      }
      const data: Record<string, string | string[]> = {
        ...values,
        upscale: Upscale.L,
      };
      await fetch(
        `https://serve.aipix.me/dummycheckout?${new URLSearchParams({
          secret,
        })}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("error");
          }
          return response.json();
        })
        .then((data) => window.location.replace(data.url))
        .catch((error) => console.log("err", error));
    },
  });

  return (
    <Box>
      <Stack maxW={["100%", "50%"]} spacing={8}>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={5} align="flex-start">
            <FormControl>
              <FormLabel htmlFor="category">Category</FormLabel>
              <Select
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value={Category.Man}>Man</option>
                <option value={Category.Woman}>Woman</option>
                <option value={Category.Cat}>Cat</option>
                <option value={Category.Dog}>Dog</option>
              </Select>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.email && !!formik.errors.email}
            >
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input
                placeholder="joe@gmail.com"
                id="email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <Button
              size="lg"
              type="submit"
              isLoading={formik.isSubmitting}
              colorScheme="pink"
            >
              Place order
            </Button>
          </VStack>
        </form>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter secret</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Secret"
                ref={secretInputRef}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>I don't have secret</Button>
            <Button
              colorScheme="pink"
              ml={3}
              onClick={() => {
                localStorage.setItem(
                  "promptSecret",
                  (secretInputRef.current as any).value
                );
                onClose();
                formik.submitForm();
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default DummyOrder;
