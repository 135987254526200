import { OrderStatus } from "./types";
import { Text, Flex, VStack } from "@chakra-ui/react";

interface TrackerProps {
  orderStatus: OrderStatus;
  orderEmail: string;
  etaText?: string;
}

function Tracker({ orderStatus, orderEmail, etaText }: TrackerProps) {
  return (
    <Flex justify="center" align="center" minHeight="500px">
      {orderStatus === OrderStatus.SUBMITTED ? (
        <VStack spacing={[4, 8]}>
          <Text fontSize={["xl", "4xl"]} textAlign="center">
            🤖 AI is training for you. We will reach out to you via email when
            we're ready.
          </Text>
          <Text fontSize="md">
            Current turnaround time ⌛: {etaText || "couple of hours"}
          </Text>
        </VStack>
      ) : null}

      {orderStatus === OrderStatus.COMPLETED ? (
        <VStack spacing={[4, 8]}>
          <Text fontSize={["xl", "4xl"]} textAlign="center">
            🎉 Your images are ready and have been sent to you at {orderEmail}.
          </Text>
          <Text fontSize="md">
            Make sure to check Spam/Junk/Promotions folders if you didn't
            receive it in your inbox yet.
          </Text>
        </VStack>
      ) : null}
    </Flex>
  );
}

export default Tracker;
