import { useEffect, useState } from "react";
import {
  Heading,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
} from "@chakra-ui/react";
import UploaderCore from "./UploaderCore";
import { Order, UploadImage } from "../types";
import fullBodyImage from "../images/full-body.svg";
import closeUpImage from "../images/close-up.svg";
import sideImage from "../images/side.svg";
import chestUpImage from "../images/chest-up.svg";
import CustomTab from "./CustomTab";

enum ImageUploaderTypes {
  CloseUp = "CLOSE_UP",
  Side = "SIDE",
  ChestUp = "CHEST_UP",
  // FullBody = "FULL_BODY",
}

const MINIMUM_NUMBER_OF_UPLOADS = {
  [ImageUploaderTypes.CloseUp]: 7,
  [ImageUploaderTypes.Side]: 2,
  [ImageUploaderTypes.ChestUp]: 2,
  // [ImageUploaderTypes.FullBody]: 2,
};

const MAXIMUM_NUMBER_OF_UPLOADS = {
  [ImageUploaderTypes.CloseUp]: 10,
  [ImageUploaderTypes.Side]: 4,
  [ImageUploaderTypes.ChestUp]: 5,
  // [ImageUploaderTypes.FullBody]: 3,
};

const IMAGE_TITLES: Record<ImageUploaderTypes, string> = {
  [ImageUploaderTypes.CloseUp]: `~${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.CloseUp]
    } close ups`,
  [ImageUploaderTypes.Side]: `~${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.Side]
    } side`,
  [ImageUploaderTypes.ChestUp]: `~${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.ChestUp]
    } chest ups`,
  // [ImageUploaderTypes.FullBody]: `~${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.FullBody]
  //   } full body`,
};

const SECTION_TITLES: Record<ImageUploaderTypes, string> = {
  [ImageUploaderTypes.CloseUp]: `Upload ${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.CloseUp]
    } to ${MAXIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.CloseUp]
    } close photos of face but showing shoulders.`,
  [ImageUploaderTypes.Side]: `Upload ${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.Side]
    } to ${MAXIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.Side]
    } close photos of face from side.`,
  [ImageUploaderTypes.ChestUp]: `Upload ${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.ChestUp]
    } to ${MAXIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.ChestUp]
    } photos from chest and up.`,
  // [ImageUploaderTypes.FullBody]: `Upload ${MINIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.FullBody]
  //   } to ${MAXIMUM_NUMBER_OF_UPLOADS[ImageUploaderTypes.FullBody]
  //   } full body photos.`,
};

const EXAMPLE_IMAGES: Record<ImageUploaderTypes, string> = {
  [ImageUploaderTypes.CloseUp]: closeUpImage,
  [ImageUploaderTypes.Side]: sideImage,
  [ImageUploaderTypes.ChestUp]: chestUpImage,
  // [ImageUploaderTypes.FullBody]: fullBodyImage,
};

interface ImageUploaderProps {
  order: Order;
  setUploadDone: (isUploadDone: boolean) => void;
  setUploadImages: (images: UploadImage[]) => void;
}

const UPLOAD_SECTIONS = [
  ImageUploaderTypes.CloseUp,
  ImageUploaderTypes.Side,
  ImageUploaderTypes.ChestUp,
  // ImageUploaderTypes.FullBody,
];

const TAB_TITLES: Record<ImageUploaderTypes, string> = {
  [ImageUploaderTypes.CloseUp]: "Close ups",
  [ImageUploaderTypes.Side]: "Side",
  [ImageUploaderTypes.ChestUp]: "Chest ups",
  // [ImageUploaderTypes.FullBody]: "Full body",
};

function ImageUploader({
  order,
  setUploadDone,
  setUploadImages,
}: ImageUploaderProps) {
  const [sectionsValidity, setSectionsValidity] = useState<
    Record<ImageUploaderTypes, boolean>
  >(() => {
    return UPLOAD_SECTIONS.reduce((res, section) => {
      return {
        ...res,
        [section]: false,
      };
    }, {}) as Record<ImageUploaderTypes, boolean>;
  });

  const [sectionsImages, setSectionsImages] = useState<
    Record<ImageUploaderTypes, UploadImage[]>
  >(() => {
    return UPLOAD_SECTIONS.reduce((res, section) => {
      return {
        ...res,
        [section]: [],
      };
    }, {}) as Record<ImageUploaderTypes, UploadImage[]>;
  });

  const setSectionValidity = (
    section: ImageUploaderTypes,
    newIsValid: boolean
  ) => {
    const beforeIsValid = sectionsValidity[section];
    if (beforeIsValid !== newIsValid) {
      setSectionsValidity((sectionsValidity) => ({
        ...sectionsValidity,
        [section]: newIsValid,
      }));
    }
  };

  const setSectionImages = (
    section: ImageUploaderTypes,
    images: UploadImage[]
  ) => {
    setSectionsImages((sectionsImages) => ({
      ...sectionsImages,
      [section]: images,
    }));
  };

  const uploadImage = (imageData: FormData) => {
    return fetch(`https://serve.aipix.me/uploadfile?order_id=${order._id}`, {
      method: "POST",
      body: imageData,
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to upload the image");
      }
      return response.json();
    });
  };

  useEffect(() => {
    const areAllSectionsValid = Object.keys(sectionsValidity)
      .map((val) => {
        return sectionsValidity[val as ImageUploaderTypes];
      })
      .every(Boolean);

    setUploadDone(areAllSectionsValid);
  }, [sectionsValidity]);

  useEffect(() => {
    setUploadImages(
      Object.keys(sectionsImages).reduce((res, val) => {
        return [
          ...res,
          ...(sectionsImages[val as ImageUploaderTypes] as UploadImage[]),
        ];
      }, [] as UploadImage[])
    );
  }, [sectionsImages]);

  return (
    <Stack spacing={4}>
      <Heading as="h2" fontSize="2xl">
        Upload the images for '{order.category}'
      </Heading>
      <Text>
        Please select your images. Once you have selected all images, click on
        Submit button below. We will then get to work.
      </Text>

      <Stack>
        <Text>Here's what we need.</Text>
        <Stack direction="row" spacing={[3, 4]}>
          {UPLOAD_SECTIONS.map((section) => (
            <Stack direction="column" align="center">
              <Image
                boxSize={["60px", "80px"]}
                objectFit="cover"
                src={EXAMPLE_IMAGES[section]}
                border="1px solid"
                borderColor="gray.300"
                rounded="md"
                backgroundColor="red"
                color="red"
              />
              <Text fontSize="xs" textTransform="uppercase">
                {IMAGE_TITLES[section]}
              </Text>
            </Stack>
          ))}
        </Stack>
        <Text>We accept atmost 25 images in total.</Text>
      </Stack>

      <Tabs pt={4} colorScheme="purple" orientation="horizontal">
        <TabList overflowY="scroll">
          {UPLOAD_SECTIONS.map((section) => (
            <CustomTab
              key={section}
              icon={EXAMPLE_IMAGES[section]}
              isSectionDone={sectionsValidity[section]}
              flexShrink={0}
            >
              {TAB_TITLES[section]}
            </CustomTab>
          ))}
        </TabList>

        <TabPanels>
          {UPLOAD_SECTIONS.map((section) => (
            <TabPanel key={section}>
              <UploaderCore
                title={SECTION_TITLES[section]}
                exampleImage={EXAMPLE_IMAGES[section]}
                uploadImage={uploadImage}
                minimumNumberOfUploads={MINIMUM_NUMBER_OF_UPLOADS[section]}
                maximumNumberOfUploads={MAXIMUM_NUMBER_OF_UPLOADS[section]}
                setValid={(isValid) => setSectionValidity(section, isValid)}
                setRemoteImages={(images) => setSectionImages(section, images)}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default ImageUploader;
