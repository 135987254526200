import { ColorModeScript, extendTheme, type ThemeConfig } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Dashboard, { loader as dashboardLoader } from "./Dashboard";
import PaymentError, { loader as paymentErrorLoader } from "./PaymentError";
import { AdminDashboard } from "./admin";
import { ChakraProvider } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme({ config })


const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "dashboard/:orderId",
    element: <Dashboard />,
    loader: dashboardLoader,
  },
  {
    path: "paymenterror/:orderId",
    element: <PaymentError />,
    loader: paymentErrorLoader,
  },
  {
    path: "admin",
    element: <AdminDashboard />,
  },
]);

const root = ReactDOM.createRoot(container);

root.render(

  <React.StrictMode>
    {/* <ColorModeScript initialColorMode={theme.config.initialColorMode}/> */}
    <ChakraProvider theme={theme} >
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
