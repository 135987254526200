import { Flex, VStack, Text, Container } from "@chakra-ui/react";
import { useContext } from "react";
import { ThemeContext } from "../App/themeContext";

interface BuyFormContainerProps {
  children: React.ReactNode;
}

function BuyFormContainer({ children }: BuyFormContainerProps) {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW="full" px={0}>
      <Flex bg="black" justify="center" align="center" py={[16, 20]} px={2}>
        <VStack spacing={12}>
          {children}
          <Text
            color="white"
            maxW={["90vw", "60vw"]}
            fontSize="md"
            textAlign="center"
          >
            After clicking Order, you'll go to the payments page. Once the
            payment is processed, we will have you upload your images. And then
            you can sit back and grab a cup of ☕ and we will send you an email
            when the generated images are ready! AI training can take 30mins to
            a couple of hours (based on traffic).
          </Text>
          <Text
            color="white"
            maxW={["90vw", "60vw"]}
            fontSize="md"
            textAlign="center"
          >
            🖼️ Note: The AI generated images will not all be perfect, hence we
            generate 8 images for each style ✨
          </Text>
        </VStack>
      </Flex>
    </Container>
  );
}

export { BuyFormContainer };
