import React from "react";
import {
  useTab,
  useMultiStyleConfig,
  Button,
  Box,
  Image,
  HStack,
  Circle,
  TabProps,
} from "@chakra-ui/react";
import { BsCheck2All } from "react-icons/bs";

type CustomTabProps = { icon: string; isSectionDone: boolean };

const CustomTab = React.forwardRef<HTMLElement, TabProps & CustomTabProps>(
  ({ isSectionDone, ...props }, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref, colorScheme: "purple" });
    const isSelected = !!tabProps["aria-selected"];

    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig("Tabs", tabProps);

    return (
      <Button __css={styles.tab} {...tabProps}>
        <HStack spacing={1} align="center">
          <Box>{tabProps.children}</Box>
          {isSectionDone ? <BsCheck2All size="18px" color="#48BB78" /> : null}
        </HStack>
      </Button>
    );
  }
);

export default CustomTab;
