import { keyframes } from "@chakra-ui/react";

export const animatedgradient = keyframes`
  0% {
    background-position: 0% 0%
  }
  50% {
    background-position: 100% 100%
  }
  100% {
    background-position: 0% 0%
  }
`;

export const MAX_UPLOADS = 25;
