import { string } from "yargs";

export enum Category {
  Man = "man",
  Woman = "woman",
  Cat = "cat",
  Dog = "dog",
}

export enum Upscale {
  M = "m",
  L = "l",
  XL = "xl",
}

export enum OrderStatus {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  COMPLETED = "COMPLETED",
}

export interface Order {
  email: string;
  category: Category;
  upscale: Upscale;
  status: OrderStatus;
  _id: string;
}

export interface QueuedUploadImage {
  id: string;
  file: File;
  localUrl: string;
}

export interface UploadImage extends QueuedUploadImage {
  isUploading: boolean;
  serverUrl?: string;
  isServerError?: Error;
}

export enum Currency {
  USD = "usd",
  INR = "inr",
  EUR = "eur",
}

export interface UpscalePrices {
  s: number;
  m: number;
  l: number;
  xl: number;
}

export interface Pricing {
  usd: UpscalePrices;
  inr: UpscalePrices;
  eur: UpscalePrices;
}

export type Style = {
  id: string;
  name: string;
  sample: string;
  category: Category;
};
