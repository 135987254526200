import { Stack, Text, Container } from "@chakra-ui/react";
import { useContext } from "react";
import { ThemeContext } from "./App/themeContext";

function TermsOfUse() {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW={theme.maxW} mt={16}>
      <Stack spacing={8}>
        <Text fontWeight="bold" fontSize={["lg"]}>
          Terms of use
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          🤖 Since the images are AI generated, the outputs are not always
          predictable. It is possible that sometimes there will be nudity in a
          few of the generated images. If you're sensitive about such results,
          please do not use this website.
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          📤 You are free to use the generated images for any purpose, including
          printing, sharing or promotions.
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          ❤️‍🔥 For your privacy, we delete your uploaded images and the model
          trained for you within 2 days after your order is processed.
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          💙 We keep the generated images for 30 days after your order is
          processed so you have enough time to download it.
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          🚫 By law, it is illegal to use this model on a child less than 18
          years old, we'd have to report you if you do.
        </Text>
        <Text fontWeight="bold" fontSize={["lg"]}>
          Credits
        </Text>
        <Text maxW={["90vw", "60vw"]} fontSize="md" textAlign="left">
          ❤️ We use the open source{" "}
          <a href="https://github.com/CompVis/stable-diffusion">
            Stable Diffusion
          </a>{" "}
          model and fine tune it with{" "}
          <a href="https://arxiv.org/abs/2208.12242">Dreambooth</a>. Kudos to
          these orgs making state of the art Machine Learning accessible to all.
        </Text>
      </Stack>
    </Container>
  );
}

export default TermsOfUse;
