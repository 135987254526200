import { useState } from "react";
import { LoaderFunctionArgs, useLoaderData } from "react-router";
import { Order } from "./types";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Flex, Text, Button, VStack } from "@chakra-ui/react";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const response = await fetch(
    `https://serve.aipix.me/order/${params.orderId}`,
    {}
  );
  const order = (await response.json()) as Order;
  return { order };
};

type LoaderData = {
  order: Order;
};

export default function PaymentError() {
  const { order } = useLoaderData() as LoaderData;
  const [isSubmitting, setSubmitting] = useState(false);

  const createNewOrder = async () => {
    const { category, email, upscale } = order;
    const payload = {
      category,
      email,
      upscale,
    };
    setSubmitting(true);
    await fetch(`https://serve.aipix.me/checkout`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error");
        }
        return response.json();
      })
      .then((data) => window.location.replace(data.url))
      .catch((error) => console.log("err", error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Container maxW="4xl" minHeight="100vh" position="relative" pt={5} pb={12}>
      <Header />
      <Flex
        justify="center"
        align="center"
        minHeight="500px"
        direction="column"
      >
        <VStack spacing={[4, 8]}>
          <Text fontSize={["md", "lg"]} textAlign="center">
            Your payment was not successful and the order could not be placed.
          </Text>
          <Button
            size="lg"
            onClick={createNewOrder}
            isLoading={isSubmitting}
            variant="outline"
            colorScheme="purple"
          >
            Try again
          </Button>
        </VStack>
      </Flex>
      <Footer />
    </Container>
  );
}
