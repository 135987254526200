const non2xxHandler = (res: Response) => {
  if (!res.ok) {
    throw new Error(`url: ${res.url}, status: ${res.status}`);
  }
  return res;
};

class CustomFetch {
  get = (url: string) => {
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then(non2xxHandler)
      .then((res) => res.json());
  };

  post = (url: string, data: Record<string, any>) => {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(non2xxHandler)
      .then((res) => res.json());
  };
}

const { get, post } = new CustomFetch();

// get ETA of order or prompt
export const getEta = (id: string) => {
  return get(`https://serve.aipix.me/eta/${id}`);
};

// get plan pricing
export const getPricing = () => {
  return get("https://serve.aipix.me/pricing");
};
