import {
  Box,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Container,
  Text,
} from "@chakra-ui/react";
import { UPLOADED_PHOTOS_EXAMPLE, STYLES_ARRANGED } from "./data";
import { ThemeContext } from "./App/themeContext";
import { useContext } from "react";

function Examples() {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW={theme.maxW} mt={16}>
      <Stack spacing={4} alignItems="stretch">
        <Heading as="h2" fontSize="2xl">
          Sample user uploads
        </Heading>
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={4}>
          {UPLOADED_PHOTOS_EXAMPLE.map((uploadedPhoto, index) => (
            <Image
              key={index}
              src={uploadedPhoto.imageUrl}
              objectFit="cover"
              borderRadius="xl"
              height="256px"
              width="100%"
            />
          ))}
        </SimpleGrid>
      </Stack>

      <Stack spacing={4} mt={16}>
        <Heading as="h2" fontSize="2xl">
          Sample AI generated results
        </Heading>
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={4}>
          {STYLES_ARRANGED.map((categoryStyles, index) => (
            <SimpleGrid
              columns={1}
              spacing={4}
              alignContent="flex-start"
              key={index}
            >
              {categoryStyles.map((imageStyle) => (
                // <a href={imageStyle.sample} target="_blank" key={imageStyle.id}>
                <Box position="relative">
                  <Image
                    src={imageStyle.sample}
                    boxSize={256}
                    objectFit="cover"
                    borderRadius="xl"
                    height="256px"
                    width="100%"
                  />
                  <Box
                    position="absolute"
                    color="white"
                    right="0"
                    bottom="0"
                    bg="black"
                    paddingX={2}
                    paddingTop={1}
                    borderTopLeftRadius="xl"
                    borderBottomRightRadius="xl"
                    fontSize="xs"
                    bgColor="rgba(0,0,0,0.5)"
                  >
                    <Text fontWeight="bold">{imageStyle.name}</Text>
                  </Box>
                </Box>
                // </a>
              ))}
            </SimpleGrid>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  );
}

export default Examples;
