import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { Category } from "../types";
import { useFormik, FormikErrors } from "formik";
import { getEta } from "../apis";

interface FormValues {
  email: string;
  category: string;
  prompt: string;
}

function TryPrompts() {
  const [numberOfPrompts, setNumberOfPrompts] = useState(1);
  const [etaMessage, setEtaMessage] = useState("");
  const [promptId, setPromptId] = useState();
  const [generatedImages, setGeneratedImages] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const secretInputRef = useRef(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: "",
      category: Category.Man,
      prompt: [""],
      negativePrompt: "",
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      const numberOfPrompts = values.prompt.filter(Boolean).length;
      if (numberOfPrompts < 1) {
        errors.prompt = "Please enter atleast 1 prompt";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const secret = localStorage.getItem("promptSecret");

      if (!secret) {
        onOpen();
        return;
      }

      setEtaMessage("");
      const data: Record<string, string | string[]> = {
        ...values,
        secret,
      };
      const { prompt, negativePrompt, ...queryParams } = data;
      const promptArray = (prompt as string[]).filter(Boolean).map((p) => ({
        prompt: p,
        guidance_scale: 12,
        negative_prompt: negativePrompt,
      }));
      localStorage.setItem("prompt", JSON.stringify(promptArray));
      await fetch(
        `https://serve.aipix.me/prompt?${new URLSearchParams(
          queryParams as Record<string, string>
        )}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(promptArray),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setPromptId(data.id);
        })
        .catch((error) => console.log("err", error));
    },
  });
  useEffect(() => {
    const fetchEta = async () => {
      if (promptId) {
        const eta = await getEta(promptId);
        return eta;
      }
    };
    const fetchGeneratedImages = (eta: number) => {
      const timeout = eta <= 2 ? 0 : eta * 60000;
      if (promptId && secret) {
        setTimeout(() => {
          const interval = setInterval(async () => {
            await fetch(
              `https://serve.aipix.me/prompt/${promptId}?${new URLSearchParams({
                secret,
              })}`,
              {
                method: "GET",
                headers: {
                  "Content-type": "application/json",
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "COMPLETE") {
                  clearInterval(interval);
                  setGeneratedImages(data.images);
                  formik.setSubmitting(false);
                } else if (data.status === "ERROR") {
                  clearInterval(interval);
                  setGeneratedImages([]);
                  formik.setSubmitting(false);
                  toast({
                    status: "error",
                    description: "Incorrect data submitted.",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-left",
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                clearInterval(interval);
                formik.setSubmitting(false);
                setGeneratedImages([]);
              });
          }, 10000);
        }, timeout);
      }
    };
    const generateImages = async () => {
      const { eta, eta_text } = await fetchEta();
      setEtaMessage(`ETA: ${eta_text}`);
      fetchGeneratedImages(eta);
    };

    const secret = localStorage.getItem("promptSecret");
    if (promptId && !formik.isSubmitting && secret) {
      formik.setSubmitting(true);
      generateImages();
    }
  }, [promptId]);
  return (
    <Stack spacing={5}>
      <Stack maxW={["100%", "50%"]} spacing={8}>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={5} align="flex-start">
            <FormControl>
              <FormLabel htmlFor="category">Category</FormLabel>
              <Select
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value={Category.Man}>Man</option>
                <option value={Category.Woman}>Woman</option>
                <option value={Category.Cat}>Cat</option>
                <option value={Category.Dog}>Dog</option>
              </Select>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.email && !!formik.errors.email}
            >
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input
                placeholder="joe@gmail.com"
                id="email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.prompt && !!formik.errors.prompt}
            >
              <FormLabel htmlFor="prompt">Prompts</FormLabel>
              {new Array(numberOfPrompts).fill(1).map((value, index) => (
                <Textarea
                  key={index}
                  height="140px"
                  placeholder="volcano eruption post apocalyptic scene with a blonde sexy cyber girl futuristic mars mad max stormy weather "
                  id="prompt"
                  name={`prompt[${index}]`}
                  value={formik.values.prompt[index]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  _notFirst={{
                    marginTop: "16px",
                  }}
                />
              ))}
              <FormErrorMessage>{formik.errors.prompt}</FormErrorMessage>

              <Stack direction="row" justify="flex-end" mt={4}>
                <Button
                  variant="outline"
                  onClick={() => setNumberOfPrompts((prompts) => prompts + 1)}
                  isDisabled={numberOfPrompts === 4}
                >
                  Add a prompt
                </Button>
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="negativePrompt">Negative prompt</FormLabel>
              <Textarea
                height="140px"
                placeholder="Negative prompt"
                id="negativePrompt"
                name="negativePrompt"
                value={formik.values.negativePrompt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                _notFirst={{
                  marginTop: "16px",
                }}
              />
            </FormControl>
            <HStack>
              <Button
                type="submit"
                isLoading={formik.isSubmitting}
                loadingText="Generating images"
                colorScheme="pink"
              >
                Generate
              </Button>
              {!etaMessage && formik.isSubmitting ? (
                <Text>Calculating ETA</Text>
              ) : null}
              {etaMessage && formik.isSubmitting ? (
                <Text>{etaMessage}</Text>
              ) : null}
            </HStack>
          </VStack>
        </form>
      </Stack>
      {generatedImages.length > 0 ? (
        <Stack spacing={4}>
          <Heading as="h2" fontSize="2xl">
            Generated images
          </Heading>

          <SimpleGrid columns={[1, 2]} spacing={[6, 12]}>
            {generatedImages.map((generatedImage) => (
              <a href={generatedImage} key={generatedImage} target="_blank">
                <Image
                  src={generatedImage}
                  objectFit="cover"
                  borderRadius="xl"
                  boxSize="512px"
                />
              </a>
            ))}
          </SimpleGrid>
        </Stack>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter secret</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Secret"
                ref={secretInputRef}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowPassword}>
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>I don't have secret</Button>
            <Button
              colorScheme="pink"
              ml={3}
              onClick={() => {
                localStorage.setItem(
                  "promptSecret",
                  (secretInputRef.current as any).value
                );
                onClose();
                formik.submitForm();
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default TryPrompts;
