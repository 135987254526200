import { Box, Text, Container } from "@chakra-ui/react";

import { useContext } from "react";
import { ThemeContext } from "./App/themeContext";

function Footer() {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW={theme.maxW}>
      <Box backgroundColor="rgba(0,0,0,0.5)" padding="5px" position="absolute" bottom={3} left="0" right="0">
        <Text textAlign="center" bottom="1px" fontWeight="semibold">
          Reach us at{" "}
          <a href="mailto:support@aipix.me" target="_blank">
            support@aipix.me
          </a>{" "}
          or{" "}
          <a href="https://twitter.com/AI_pix_" target="_blank">
            @AI_pix_ 🐦
          </a>
        </Text>
      </Box>
    </Container>
  );
}

export default Footer;
