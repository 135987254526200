import { useRef, ChangeEvent, useState, useEffect } from "react";
import {
  Stack,
  Heading,
  Text,
  SimpleGrid,
  Box,
  Input,
  Flex,
  Tooltip,
  Progress,
  Image,
  IconButton,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { InfoOutlineIcon, CloseIcon } from "@chakra-ui/icons";
import { FaFileImage } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { UploadImage, QueuedUploadImage } from "../types";

interface ImageUploaderProps {
  title: string;
  exampleImage: string;
  uploadImage: (imageData: FormData) => Promise<any>;
  minimumNumberOfUploads: number;
  maximumNumberOfUploads: number;
  setValid: (isValid: boolean) => void;
  setRemoteImages: (images: UploadImage[]) => void;
}

function ImageUploader({
  title,
  exampleImage,
  uploadImage,
  minimumNumberOfUploads,
  maximumNumberOfUploads,
  setValid,
  setRemoteImages,
}: ImageUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<UploadImage[]>([]);
  const [imagesInQueue, setImagesInQueue] = useState<QueuedUploadImage[]>([]);
  const numberOfSuccessfulImages = images.filter(
    (image) => image.serverUrl
  ).length;

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (imageId: string) => {
    setImages((images) => images.filter((image) => image.id !== imageId));
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const filesArray = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        filesArray.push({
          id: uuid(),
          file: file,
          localUrl: URL.createObjectURL(file),
        });
      }
    }
    setImagesInQueue([...filesArray]);
    setImages([
      ...filesArray.map((file) => ({
        ...file,
        isUploading: true,
      })),
      ...images,
    ]);
  };

  useEffect(() => {
    imagesInQueue.forEach(async (imageInQueue) => {
      const imageId = imageInQueue.id;
      const formData = new FormData();
      formData.append("f", imageInQueue.file);

      uploadImage(formData)
        .then((data) => {
          setImages((images) =>
            images.map((image) => {
              if (image.id === imageId) {
                return {
                  ...image,
                  serverUrl: data.url,
                  isUploading: false,
                };
              }
              return image;
            })
          );
        })
        .catch((error) => {
          setImages((images) =>
            images.map((image) => {
              if (image.id === imageId) {
                return {
                  ...image,
                  isServerError: error,
                  isUploading: false,
                };
              }
              return image;
            })
          );
        });
    });
  }, [imagesInQueue]);

  useEffect(() => {
    const numberOfSuccessfulImages = images.filter(
      (image) => image.serverUrl
    ).length;
    if (numberOfSuccessfulImages >= minimumNumberOfUploads) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [images]);

  useEffect(() => {
    setRemoteImages(images);
  }, [images]);

  return (
    <Stack spacing={5}>
      <Stack
        justify={["flex-start", "space-between"]}
        direction={["column", "row"]}
        align={["flex-start", "center"]}
        spacing={3}
      >
        <HStack align="center" spacing={3}>
          <Image
            boxSize="40px"
            objectFit="cover"
            src={exampleImage}
            rounded="md"
          />
          <Text>{title}</Text>
        </HStack>
        <Text>{numberOfSuccessfulImages} selected</Text>
      </Stack>

      <SimpleGrid columns={[1, 2, 4]} spacing={4}>
        <Box>
          <Input
            type="file"
            multiple
            onChange={handleImageChange}
            // accept="image/*"
            display="none"
            ref={fileInputRef}
          />
          <Flex
            borderWidth="2px"
            borderStyle="dashed"
            borderColor="gray.300"
            borderRadius="lg"
            justify="center"
            align="center"
            minHeight="250px"
            cursor="pointer"
            onClick={handleUploadButtonClick}
          >
            <Stack align="center" spacing={4} p={2}>
              <FaFileImage size="24px" color="#805AD5" />
              <Text align="center" fontSize="md">
                Browse to choose your images.
              </Text>
            </Stack>
          </Flex>
        </Box>
        {images.map((image) => (
          <Stack key={image.localUrl}>
            <Box position="relative">
              <a href={image.serverUrl} target="_blank">
                <Image
                  src={image.serverUrl}
                  objectFit="cover"
                  borderRadius="lg"
                  height="250px"
                  width="100%"
                />
              </a>
              {!image.isUploading ? (
                <Tooltip label="Remove the image">
                  <IconButton
                    aria-label="Remove image"
                    icon={<CloseIcon />}
                    size="xs"
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={() => handleRemoveImage(image.id)}
                  />
                </Tooltip>
              ) : null}
            </Box>

            <Progress
              visibility={image.isUploading ? "visible" : "hidden"}
              size="xs"
              colorScheme="pink"
              isIndeterminate
            />

            {image.isServerError ? (
              <Stack align="center">
                <Tooltip label={"The image couldn't be uploaded to our servers." + image.isServerError}>
                  <InfoOutlineIcon color="red.500" boxSize="18px" />
                </Tooltip>
              </Stack>
            ) : null}
          </Stack>
        ))}
      </SimpleGrid>
    </Stack>
  );
}

export default ImageUploader;
