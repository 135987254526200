import cat from "./images/input/thumbnails/cat.jpg";
import dog from "./images/input/thumbnails/dog.jpeg";
import inputMan from "./images/input/thumbnails/man.jpg";
import inputWoman from "./images/input/thumbnails/woman.jpg";

import woman_christmas from "./images/output/thumbnails/woman_christmas.jpeg";
import woman_glow from "./images/output/thumbnails/woman_glow.jpeg";
import woman_goth from "./images/output/thumbnails/woman_goth.jpeg";
import woman_superhero from "./images/output/thumbnails/woman_superhero.jpeg";
import woman_sketch from "./images/output/thumbnails/woman_sketch.jpeg";
import woman_model from "./images/output/woman_model.jpeg";

import man_anime from "./images/output/thumbnails/man_anime.png";
import man_jungle from "./images/output/thumbnails/man_jungle.png";
import man_pirate from "./images/output/thumbnails/man_pirate.png";
import man_psych from "./images/output/thumbnails/man_psych.png";
import man_viking from "./images/output/thumbnails/man_viking.png";

import cat_linkedin from "./images/output/thumbnails/cat_linkedin.png";
import cat_nba from "./images/output/thumbnails/cat_nba.png";
import cat_retro from "./images/output/thumbnails/cat_retro.png";
import cat_space from "./images/output/thumbnails/cat_space.png";
import cat_snow from "./images/output/thumbnails/cat_snow.png";

import dog_formal from "./images/output/thumbnails/dog_formal.png";
import dog_futuristic from "./images/output/thumbnails/dog_futuristic.png";
import dog_marvel from "./images/output/thumbnails/dog_marvel.png";
import dog_punk from "./images/output/thumbnails/dog_punk.png";
import dog_hike from "./images/output/thumbnails/dog_hike.png";

import placeholder from "./images/output/placeholder.png";


import { Category, Style } from "./types";

/**
 * Assign sample to each style and add a category too.
 * For example if we want to show man in a jungle style, add man's image in sample
 * and also add category as man. We use categories for organising the grid.
 * For symmetrical grid, make sure that we assign equal number of styles to each category.
 *
 * Grid shows up as follows
 *
 * Man 1, Woman 1, Dog 1, Cat 1
 * Man 2, Woman 2, Dog 2, Cat 2
 * Man 3, Woman 3, Dog 3, Cat 3
 * Man 4, Woman 4, Dog 4, Cat 4
 * Man 5, Woman 5
 */

export const UPLOADED_PHOTOS_EXAMPLE = [
  inputWoman,
  inputMan,
  cat,
  dog,
].map((inputImage) => ({
  imageUrl: inputImage,
}));

const styles: Style[] = [
  {
    id: "viking",
    name: "🗡️ Viking",
    sample: man_viking,
    category: Category.Man,
  },
  {
    id: "glow",
    name: "✨ Glow",
    sample: woman_glow,
    category: Category.Woman,
  },
  {
    id: "gala",
    name: "👠 Gala",
    sample: dog_formal,
    category: Category.Dog,
  },
  {
    id: "marvel",
    name: "🌟 Marvel",
    sample: dog_marvel,
    category: Category.Dog,
  },
  {
    id: "punk",
    name: "🤘 Punk",
    sample: dog_punk,
    category: Category.Dog,
  },
  {
    id: "psychedelic",
    name: "💊 Psychedelic",
    sample: man_psych,
    category: Category.Man,
  },
  {
    id: "superhero",
    name: "🦸 Superhero",
    sample: woman_superhero,
    category: Category.Woman,
  },
  {
    id: "retro",
    name: "🕶️ Retro",
    sample: cat_retro,
    category: Category.Cat,
  },
  {
    id: "space",
    name: "🪐 Space",
    sample: cat_space,
    category: Category.Cat,
  },
  {
    id: "goth",
    name: "🖤 Goth",
    sample: woman_goth,
    category: Category.Woman,
  },
  {
    id: "sketch",
    name: "✏️ Sketch",
    sample: woman_sketch,
    category: Category.Woman,
  },
  {
    id: "christmas",
    name: "🎄 Christmas",
    sample: woman_christmas,
    category: Category.Woman,
  },
  {
    id: "nba",
    name: "🏀 NBA",
    sample: cat_nba,
    category: Category.Cat,
  },
  {
    id: "futuristic",
    name: "🦾 Futuristic",
    sample: dog_futuristic,
    category: Category.Dog,
  },
  {
    id: "anime",
    name: "👾 Anime",
    sample: man_anime,
    category: Category.Man,
  },
  {
    id: "pirate",
    name: "🏴‍☠️ Pirate",
    sample: man_pirate,
    category: Category.Man,
  },
  {
    id: "jungle",
    name: "🌿 Jungle",
    sample: man_jungle,
    category: Category.Man,
  },
  {
    id: "snow",
    name: "❄️ Snow",
    sample: cat_snow,
    category: Category.Cat,
  },
  {
    id: "linkedin",
    name: "👔 LinkedIn",
    sample: cat_linkedin,
    category: Category.Cat,
  },
  {
    id: "hike",
    name: "🏔️ hike",
    sample: dog_hike,
    category: Category.Dog,
  }
];

const isManStyle = (style: Style) => style.category === Category.Man;
const isWomanStyle = (style: Style) => style.category === Category.Woman;
const isDogStyle = (style: Style) => style.category === Category.Dog;
const isCatStyle = (style: Style) => style.category === Category.Cat;

export const STYLES_ARRANGED = [
  styles.filter(isWomanStyle),
  styles.filter(isManStyle),
  styles.filter(isCatStyle),
  styles.filter(isDogStyle),
];

export const UPLOAD_DOS = [
  "Only the subject (person/pet) in the photo, not any other people",
  "Photos with different facial expressions",
  "Photos in different locations/backgrounds/angles",
  "Photos at different times of day",
  "JPG, PNG, HEIC (iphone) supported",
  "Only images > 512x512",
];

export const UPLOAD_DONTS = [
  "No photo shoots (in same background and clothes)",
  "Not the same expression on every photo",
  "None or light makeup",
  "Not other people in the photo",
  "No sunglasses",
  "No nudes",
  "No kids",
];

export const PRICING = {
  usd: {
    s: 500,
    m: 500,
    l: 500,
    xl: 1000,
  },
  eur: {
    s: 500,
    m: 500,
    l: 500,
    xl: 1000,
  },
  inr: {
    s: 49900,
    m: 49900,
    l: 49900,
    xl: 99900,
  },
};
