import { Heading, Stack, Container } from "@chakra-ui/react";
import { useContext } from "react";
import { ThemeContext } from "./App/themeContext";

function Header() {
  const theme = useContext(ThemeContext);
  return (
    <Container maxW={theme.maxW}>
      <Stack spacing={4}>
        <Heading
          as="h1"
          fontSize="5xl"
          background="-webkit-linear-gradient(45deg, #f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82)"
          backgroundClip="text"
          textColor="transparent"
          backgroundSize={["300px", "100px"]}
        >
          AI Pix
        </Heading>
        <Heading as="h2" fontSize="2xl">
          Generate HD portraits for a Person 👨🏿 👩🏻 or for a Pet 🐶 🐱
        </Heading>
      </Stack>
    </Container>
  );
}

export default Header;
